
import { defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import FileImage from '@/components/icons/FileImage.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'MemberDetail',
  components: {
    Container,
    IconBase,
    FileImage
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const partnerIdParam = ref<number>();
    const route = useRoute();

    onMounted(async () => {
      partnerIdParam.value = route.params.partnerId
        ? parseInt(route.params.partnerId as string)
        : undefined;

      // MemberDetail
      if (!partnerIdParam.value) {
        alert('There is no partner id');
      } else {
        await fetchMemberInfo(partnerIdParam.value);
      }
    });

    const fetchMemberInfo = async (partnerId) => {
      try {
        const { data } = await partnerAPI.adminMember.adminMemberPartner({
          partnerId,
        });
        memberDetail.value = (data as any).data;

      } catch (e) {
        alert(getServerErrorMessage(e));
        window.close()
      }
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    return {
      memberDetail,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
